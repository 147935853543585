import { template as template_068c09e7d4ad4e1d96af654c188ef150 } from "@ember/template-compiler";
const SidebarSectionMessage = template_068c09e7d4ad4e1d96af654c188ef150(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
