import { template as template_9dc83cc6f05d4baf9230c7252cf1c8a1 } from "@ember/template-compiler";
const FKLabel = template_9dc83cc6f05d4baf9230c7252cf1c8a1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
