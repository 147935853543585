import { template as template_4c93c7c889634f1bb87c54de69641df5 } from "@ember/template-compiler";
const WelcomeHeader = template_4c93c7c889634f1bb87c54de69641df5(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
